import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!

  return (_openBlock(), _createBlock(_component_v_app_bar, {
    fixed: "",
    color: "rgba(249, 160, 63, 1)"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_router_link, {
        to: "/",
        class: "text-white text-decoration-none ml-4"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, null, {
            default: _withCtx(() => [
              _createTextVNode(" StudySpots ")
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_spacer),
      _createVNode(_component_router_link, {
        to: "/login",
        class: "text-white text-decoration-none mr-4"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, null, {
            default: _withCtx(() => [
              _createTextVNode(" Login ")
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/signup",
        class: "text-white text-decoration-none mr-4"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, null, {
            default: _withCtx(() => [
              _createTextVNode(" Sign Up ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}