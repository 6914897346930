
import { Options, Vue } from 'vue-class-component';
import { login } from '@/services/auth'
import router from '@/router';

@Options({
  components: {},
})
export default class SignUpCard extends Vue {
  username = ''
  password = ''
  showPassword = false

  error = false
  message = ''

	async login(): Promise<void> {
		const response = await login({
			username: this.username,
			password: this.password,
		})

		const accessToken = response.data?.AuthenticationResult?.AccessToken
		if (accessToken){
			localStorage.setItem('accessToken', accessToken)
		}

		// if (response.status === 200){
		// 	router.push({ name: 'home' })
		// }
	}
}
