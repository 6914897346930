import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { nextTick } from 'vue';
import Empty from '../pages/Empty.vue'
import Home from '../pages/Home.vue'
import SignUp from '../pages/SignUp.vue'
import Login from '../pages/Login.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'StudySpots'
    }
  },
  {
    path: '/signup',
    component: Empty,
    meta: {
      title: 'StudySpots | Sign Up'
    },
    children: [
      { 
        path: '',
        name: 'signup', 
        component: SignUp
      },
      { 
        path: 'verify',
        name: 'signup.verify',
        component: SignUp 
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'StudySpots | Login'
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})


const DEFAULT_TITLE = 'StudySpots';
router.afterEach((to, from) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    nextTick(() => {
      const routeTitle = typeof to.meta.title === "string" ? to.meta.title : ''
      document.title = routeTitle || DEFAULT_TITLE
    })
});

export default router
