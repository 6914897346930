import axios from 'axios'
import { SignUpDTO, VerifyEmailDTO, LoginDTO } from '@/dtos/Auth'

export const signUp = async (payload: SignUpDTO) => {
    const host = process.env.VUE_APP_STUDY_SPOTS_API_HOST
    const url = host + '/auth/signup'
    return axios.post(url, payload)
}

export const verify = async (payload: VerifyEmailDTO) => {
    const host = process.env.VUE_APP_STUDY_SPOTS_API_HOST
    const url = host + '/auth/verify'
    return axios.post(url, payload)
}

export const login = async (payload: LoginDTO) => {
    const host = process.env.VUE_APP_STUDY_SPOTS_API_HOST
    const url = host + '/auth/signin'
    return axios.post(url, payload)
}