
import { Options, Vue } from 'vue-class-component';
import { verify } from '@/services/auth'
import router from '@/router';

@Options({
  components: {},
})
export default class SignUpCard extends Vue {
  username = ''
  verificationCode = ''

	mounted(): void {
		this.username = typeof this.$route.params?.username === 'string' ? this.$route.params.username : ''
	}
  
	async verify(): Promise<void> {
		const response = await verify({
			username: this.username,
			code: this.verificationCode,
		})

		if (response.status === 200){
			router.push({ name: 'home' })
		}
	}

}
