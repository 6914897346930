
import { Options, Vue } from 'vue-class-component';
import SignUpCard from '@/components/SignUpCard.vue'
import VerifyCard from '@/components/VerifyCard.vue'

@Options({
	components: { SignUpCard, VerifyCard },
})
export default class SignUp extends Vue {
	get showSignUp(): boolean {
		return this.$route.name === 'signup'
	}

	get showVerify(): boolean {
		return this.$route.name === 'signup.verify'
	}
}
