
import { Options, Vue } from 'vue-class-component';
import { signUp } from '@/services/auth'
import router from '@/router';

@Options({
  components: {},
})
export default class SignUpCard extends Vue {
  firstName = ''
  lastName = ''
  username = ''
  email = ''
  password = ''
  showPassword = false
  
	async signUp(): Promise<void> {
		const response = await signUp({
			name: this.firstName,
			family_name: this.lastName,
			username: this.username,
			email: this.email,
			password: this.password
		})

		if (response.status === 200){
			router.push({ name: 'signup.verify', params: { username: this.username }})
		}
	}

}
